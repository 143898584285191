import {Component, OnInit} from '@angular/core';
import {LangRepository} from '../../shared/lang/lang.repository';
import {ConfirmationService, MessageService, SelectItem} from 'primeng/api';
import {ActivatedRoute, Router} from '@angular/router';
import {TopLineService} from "../shared/top-line.service";


@Component({
  selector: 'app-top-line',
  templateUrl: './top-line.component.html',
  styleUrls: ['./top-line.component.css']
})
export class TopLineComponent implements OnInit {

  caret: boolean = false;
  interface: SelectItem[];
  interfaceKaz: SelectItem[];
  selectedInterface: number;

  constructor(
    public langRepository: LangRepository,
    private router: Router,
    private route: ActivatedRoute,
    public confirmationService: ConfirmationService,
    public lineService: TopLineService,
    public messageService: MessageService
  ) { }

  ngOnInit() {
    this.interface = [
      {label: 'Выберите раздел', value: 0},
      {label: 'Аудиторный фонд', value: 1},
      // {label: 'Организации образования', value: 2},
      // {label: 'Редактировать данные ППЕНТ', value: 4},
      // {label: 'Технические средства', value: 3}
    ];

    this.interfaceKaz = [
      {label: 'Бөлімді таңдаңыз', value: 0},
      {label: 'Аудиториялық фонд', value: 1},
      // {label: 'Білім беру ұйымдары', value: 2},
      // {label: 'ҰБТӨП деректерін өзгерту', value: 4},
      // {label: 'Техникалық құралдар', value: 3}
    ];

    switch (this.route.routeConfig.path) {
      case 'audit':
        this.selectedInterface = 1;
        break;
      case 'schools':
        this.selectedInterface = 2;
        break;
      case 'collect':
        this.selectedInterface = 3;
        break;

    }
  }


  routerLink(event) {
    switch (event.value) {
      case 1:
        this.router.navigate(['/audit']);
        break;
      case 2:
        this.router.navigate(['/schools']);
        break;
      case 3:
        this.router.navigate(['/collect']);
        break;
      case 4:
        this.router.navigate(['/check-in']);
        break;
    }
  }


  confirmCloseDb() {
    this.confirmationService.confirm({
      message: this.langRepository.wordList[175],
      accept: () => {
        this.checkForCloseTechData();
        // this.closeTechData(); // Временно при запуске Базы ппент убрать
      }
    });
  }

  closeTechData() {
    this.lineService.closeDbTechData()
      .subscribe(data => {
        this.addSingle('База данных технических средств успешно закрыта', 'success');
      }, error => {
        this.addSingle(error.errorMsgRus, 'error');
      })
  }


  closeDb() {
    this.lineService.closeDb()
      .subscribe(data => {
        this.addSingle('База данных ППЕНТ успешно закрыта', 'success');
      }, error => {
        this.addSingle(error.errorMsgRus, 'error');
      })
  }

  // Проверить статус для закрытия тех. средств.
  checkForCloseTechData() {
      this.lineService.checkForCloseTechData()
        .subscribe(data => {
          this.closeBasePPENT();
        }, error => {
          this.addSingle(`Не удалось закрыть базу данных ${error.errorMsgRus}`, 'error');
        })
  }

  closeBasePPENT() {
      this.lineService.closeDb(1)
        .subscribe(data => {
          this.closeDb();
        }, error => {
          this.addSingle(`Не удалось закрыть базу данных ${error.errorMsgRus}`, 'error');
        })
  }


  addSingle(text: string, status: string = 'error') {
    this.messageService.add({severity: status, summary:'Сервисное сообщение', detail: text});
  }

  exit() {
    sessionStorage.clear();
    this.router.navigate(['/auth']);
  }

}
