import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {CollectDataService} from '../shared/services/collect-data.service';
import {MetalloiskatelModel} from '../shared/model/metalloiskatel.model';
import {ActivatedRoute} from '@angular/router';
import {UpdateService} from '../shared/services/update.service';
import {EditMetaloiskatelComponent} from './edit-metaloiskatel/edit-metaloiskatel.component';
import {AddMetaloiskatelComponent} from './add-metaloiskatel/add-metaloiskatel.component';
import {ConfirmationService} from 'primeng/api';
import {CatalogRepository} from '../shared/services/catalog.repository';
import {LangRepository} from '../../shared/lang/lang.repository';

@Component({
  selector: 'app-metalloiskatel',
  templateUrl: './metalloiskatel.component.html',
  styleUrls: ['./metalloiskatel.component.css']
})
export class MetalloiskatelComponent implements OnInit {

  metalLists: MetalloiskatelModel[];
  currentIdVpt: MetalloiskatelModel;
  idVpt: string;

  @ViewChild(EditMetaloiskatelComponent)
  editMetaloiskatelComponent: EditMetaloiskatelComponent;

  @ViewChild(AddMetaloiskatelComponent)
  addMetaloiskatelComponent: AddMetaloiskatelComponent;

  @Output()
  delete: EventEmitter<any> = new EventEmitter();

  constructor(
    private collectService: CollectDataService,
    private route: ActivatedRoute,
    public updateService: UpdateService,
    private collectDataService: CollectDataService,
    private confirmationService: ConfirmationService,
    public catalogRepository: CatalogRepository,
    public langRepository: LangRepository
  ) { }

  ngOnInit() {
    this.idVpt = this.route.snapshot.params['id'];

    this.currentIdVpt = new MetalloiskatelModel(
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    );


    this.collectService.getEquipmentData('metalDetector')
      .subscribe(data => {
        // console.log(data);
        this.metalLists = data.list;
      });
  }

  activeAudit(audit) {
    this.currentIdVpt = audit;
  }


  add() {
    this.addMetaloiskatelComponent.show();
  }

  edit() {
    this.editMetaloiskatelComponent.show(this.currentIdVpt, this.idVpt);
  }







  update(event: MetalloiskatelModel) {
    console.log(event);
    this.updateService.update(event, this.metalLists,'metalDetectorId');
  }

  push(event) {
    console.log(event);

    this.updateService.dataPush(event, this.metalLists);
  }

  deleteMetalloiskatel() {
    this.delete.emit({
      equipmentId: this.currentIdVpt.metalDetectorId,
      equipmentName: 'metalDetector',
      equipmentIdName: 'metalDetectorId',
      equipmentLists: this.metalLists
    });
  }



}
