import {Injectable, OnInit} from '@angular/core';

import {MessageService, SelectItem} from 'primeng/api';
import {AuditService} from './audit.service';
import {catchError, map} from 'rxjs/operators';
import {LangRepository} from '../../../shared/lang/lang.repository';


@Injectable()
export class AuditRepository {

  airConditionSystem: SelectItem[] = [];
  toiletLocation: SelectItem[] = [];
  yesNo: SelectItem[];
  yesNoKaz: SelectItem[];
  auditLists: SelectItem[] = [];
  reasonList: SelectItem[] = [];
  transmitVideoOnlineReasonList: SelectItem[] = [];
  transmitVideoOnlineReasonListKaz: SelectItem[] = [];
  reasonListKaz: SelectItem[] = [];


  constructor(
    private auditService: AuditService,
    public messageService: MessageService,
    public langRepository: LangRepository

  ) {
    this.yesNo = [
      {
        label: 'Выберите статус',
        value: null
      },
      {
        label: 'Нет',
        value: 0
      },
      {
        label: 'Да',
        value: 1
      }
    ];

    this.yesNoKaz = [
      {
        label: 'Мәртебені таңдаңыз',
        value: null
      },
      {
        label: 'Жоқ',
        value: 0
      },
      {
        label: 'Иә',
        value: 1
      }
    ];

    this.start();
    this.getWhyNotHaveReasonList();
    this.getWhyNotCanTransmitVideoOnlineReasonList();

  }

  start() {
    this.airConditionSystem = [];
    this.toiletLocation = [];
    this.auditService.getAriCondition()
      .subscribe(data => {
        this.pushArray(data.items, this.airConditionSystem);
      })

    this.auditService.getToiletLocationList()
      .subscribe(data => {
        this.pushArray(data.items, this.toiletLocation);
      })


  }

  getFreeAudit(auditId: number = 0) {
    this.auditLists = [];
    return this.auditService.getFreeAudit(auditId)
      .pipe(
        map(data => {
          data.items.forEach((item, i) => {
            this.auditLists.push({
              value: item.toString(),
              label: item
            })
          })
          // console.log(this.auditLists)
        }),
        catchError((error) => {
          throw(error.json())
        })
      );
  }

  getWhyNotCanTransmitVideoOnlineReasonList() {
    this.transmitVideoOnlineReasonList = [];
    this.auditService.getWhyNotCanTransmitVideoOnlineReasonList()
      .subscribe(data => {
        this.transmitVideoOnlineReasonList = [];
        data.items.forEach((item, i) => {
          this.transmitVideoOnlineReasonList.push({
            label: item.nameRus,
            value: item.id,
            title: item.needAdditionalText
          })
        })

        data.items.forEach((item, i) => {
          this.transmitVideoOnlineReasonListKaz.push({
            label: item.nameKaz,
            value: item.id,
            title: item.needAdditionalText
          })
        })
      })
  }

  getWhyNotHaveReasonList() {
    this.reasonList = [];
    this.auditService.getWhyNotHaveReasonList()
      .subscribe(data => {
        this.pushArray(data.items, this.reasonList);
        this.pushArray(data.items, this.reasonListKaz, 'nameKaz');
      })
  }

  pushArray(data, arr, type: string = 'nameRus' ) {
    data.forEach((item, i) => {
      arr.push({
        label: item[type],
        value: item.id
      })
    })
  }







}
