import { Component, OnInit } from '@angular/core';
import {AppService} from '../shared/app.service';
import {RegisterModel} from '../shared/model/register.model';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {LangRepository} from '../shared/lang/lang.repository';
import {ActivatedRoute, Router} from '@angular/router';
import {MessageService} from 'primeng/api';

@Component({
  selector: 'app-check-in',
  templateUrl: './check-in.component.html',
  styleUrls: ['./check-in.component.css']
})
export class CheckInComponent implements OnInit {

  ppentData: RegisterModel;
  myForm: FormGroup;
  buttonShow: boolean = false;

  constructor(
    private appService: AppService,
    public langRepository: LangRepository,
    public router: Router,
    public messageService: MessageService,
    private route: ActivatedRoute
    ) { }

  ngOnInit() {
    if (this.route.snapshot.params.auth === 'true') {
      this.buttonShow = false;
    } else {
      this.buttonShow = true;
    }


    this.myForm = this.formValidation(new RegisterModel());

    this.appService.getCurrentDataPpent()
      .subscribe(data => {
        this.ppentData = data.data;
        console.log(this.ppentData)
        this.myForm = this.formValidation(this.ppentData);
      });
  }

  formValidation (ppentData: RegisterModel) {
    return new FormGroup({
      nameKaz: new FormControl(ppentData.nameKaz, [Validators.required]),
      nameRus: new FormControl(ppentData.nameRus, [Validators.required]),
      shortNameKaz: new FormControl(ppentData.shortNameKaz, [Validators.required]),
      shortNameRus: new FormControl(ppentData.shortNameRus, [Validators.required]),
      addressKaz: new FormControl(ppentData.addressKaz, [Validators.required]),
      addressRus: new FormControl(ppentData.addressRus, [Validators.required]),
      phoneNumber: new FormControl(ppentData.phoneNumber, [Validators.required]),
      email: new FormControl(ppentData.email, [Validators.required]),
      directorFullname: new FormControl(ppentData.directorFullname, [Validators.required]),
      directorBirthDate: new FormControl(ppentData.directorBirthDate, [Validators.required]),
      directorHomePhoneNumber: new FormControl(ppentData.directorHomePhoneNumber, [Validators.required]),
      directorWorkPhoneNumber: new FormControl(ppentData.directorWorkPhoneNumber, [Validators.required]),
      directorMobilePhoneNumber: new FormControl(ppentData.directorMobilePhoneNumber, [Validators.required]),
      engineerFullname: new FormControl(ppentData.engineerFullname, [Validators.required]),
      engineerHomePhoneNumber: new FormControl(ppentData.engineerHomePhoneNumber, [Validators.required]),
      engineerBirthDate: new FormControl(ppentData.engineerBirthDate, [Validators.required]),
      engineerWorkPhoneNumber: new FormControl(ppentData.engineerWorkPhoneNumber, [Validators.required]),
      engineerMobilePhoneNumber: new FormControl(ppentData.engineerMobilePhoneNumber, [Validators.required])
    });
  }


  dateSelect(calendar, type) {

    const date = calendar;
    let year, month, day;
    year = date.getFullYear();
    month = date.getMonth() + 1;
    day = date.getDate();

    if (month < 10) month = '0' + month;

    if (day < 10) day = '0' + day;

    const birthDate = `${day}/${month}/${year}`;

    this.myForm.value[type] = birthDate;

  }


  save() {
    if (typeof this.myForm.value.directorBirthDate === 'object') {
      this.dateSelect(this.myForm.value.directorBirthDate,'directorBirthDate');
    }

    if (typeof this.myForm.value.engineerBirthDate === 'object') {
      this.dateSelect(this.myForm.value.engineerBirthDate,'engineerBirthDate');
    }

    console.log(this.myForm.value)

    this.appService.saveDataPpent(this.myForm.value)
      .subscribe(data => {
        // console.log(data);
        this.router.navigate(['/audit', { status: 'success' }]);
      }, error => {
        this.addSingle(error.errorMsgRus)
      })
  }

  addSingle(text: string, status: string = 'success') {
    console.log(text);
    this.messageService.add({severity: status, summary:'Сервисное сообщение', detail:text});
  }

}
