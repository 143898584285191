import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {AuditService} from './shared/services/audit.service';
import {AuditModel} from './shared/model/audit.model';
import {ConfirmationService, MessageService} from 'primeng/api';
import {EditAuditComponent} from './edit-audit/edit-audit.component';
import {AuditListsComponent} from './audit-lists/audit-lists.component';
import {AddAuditComponent} from './add-audit/add-audit.component';
import {SchemeAuditComponent} from './scheme-audit/scheme-audit.component';
import {LangRepository} from '../shared/lang/lang.repository';
import {ActivatedRoute, Router} from '@angular/router';






@Component({
  selector: 'app-audit',
  templateUrl: './audit.component.html',
  styleUrls: ['audit.component.css'],
  providers: [ConfirmationService, MessageService]
})


export class AuditComponent implements  OnInit {


  @ViewChild(EditAuditComponent)
  private editComponent: EditAuditComponent;

  @ViewChild(AddAuditComponent)
  private addComponent: AddAuditComponent;

  @ViewChild(AuditListsComponent)
  private auditListsComponent: AuditListsComponent;

  @ViewChild(SchemeAuditComponent)
  private schemeComponent: SchemeAuditComponent;

  hasScheme: AuditModel;
  auditList:  AuditModel[];
  scheme: string = '';
  editAudits: AuditModel;
  currentAudit: AuditModel;

  constructor(
    public auditService: AuditService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    public langRepository: LangRepository,
    public route: ActivatedRoute,
    public router: Router
  ) {  }


  ngOnInit() {
    this.currentAudit = new AuditModel(
                                      null,
                                      null,
                                      null,
                                      null,
                                      null,
                                      null,
                                      null,
                                      null,
                                      null,
                                      null,
                                      null,
                                      null,
                                      null,
                                      null,
                                      null,
                                      null,
                                      null,
                                      null,
                                      null,
                                      null,
                                      null,
                                      null ,
                                      null
      );


    if (this.route.snapshot.params.status === 'success') {
      setTimeout(() => {
        this.addSingle('Данные успешно сохранены');
        // console.log('sd')
        this.router.navigate(['./audit']);
      }, 1000)
    }

    this.auditService.getAuditList()
      .subscribe(data => {
        this.auditList = data.items;
      })
  }

  passAudit(audit: AuditModel) {
    if (audit.hasScheme === 1) {
      this.hasScheme = audit;
      this.auditService.getScheme(audit.num)
        .subscribe(data => {
          this.scheme = data.data.scheme;

        })
    }
  }




  saveScheme(event) {
    console.log(event, this.hasScheme.hasScheme);
  }

  editAudit(audit: AuditModel) {
      this.editAudits = audit;
  }





  addMultiple() {
    this.messageService.addAll([{severity:'success', summary:'Service Message', detail:'Via MessageService'},
      {severity:'info', summary:'Info Message', detail:'Via MessageService'}]);
  }

  confirm() {
    this.confirmationService.confirm({
      message: this.langRepository.wordList[144],
      accept: () => {
        this.deleteAudit();
      }
    });
  }

  clear() {
    this.messageService.clear();
  }

  openDialogEdit() {
    this.editComponent.show(this.currentAudit.id);
  }

  openDialogAdd() {
    this.addComponent.showDialog();
  }

  openDialogScheme() {
    this.auditService.getScheme(this.currentAudit.id)
      .subscribe(data => {
        // console.log(data);
        this.schemeComponent.showDialog(data.data.scheme);
      }, error => {
        this.addSingle(error.errorMsgRus, 'error');
      })

  }

  updateAudit(audit) {
    this.currentAudit = audit;
  }

  editChildComponent(audit: any) {
    // console.log(audit);
    this.auditListsComponent.updateAudit(this.currentAudit.id, audit);
  }

  deleteAudit() {
    this.auditService.deleteAudit(this.currentAudit.id)
      .subscribe(data => {
        this.auditListsComponent.deleteAudit(this.currentAudit.num);
        this.addSingle(this.langRepository.wordList[121]);
        this.currentAudit = new AuditModel();
      },error => {
        this.addSingle(error.errorMsgRus, 'error');
      })
  }

  addSingle(text: string, status: string = 'success') {
    this.messageService.add({severity: status, summary:'Service Message', detail:text});
  }

  addChildComponent(audit) {
    this.auditListsComponent.auditList.push(audit);
    this.addSingle(this.langRepository.wordList[120]);
  }


}
