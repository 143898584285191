import {Injectable} from "@angular/core";
import {BaseApi} from "../../shared/core/base-api";
import {Http} from "@angular/http";

@Injectable()
export class TopLineService extends BaseApi {

  constructor(public http: Http) {
    super(http);
  }


  checkForCloseTechData() {
    return this.get(`/api/nct/tech_data/api/ppent/techDataStatus/check`);
  }

  closeDb(check?: number) {
    return this.post(`api/ppent/me/close${check === 1 ? '?check=1' : ''}`);
  }

  closeDbTechData() {
    return this.post(`/api/nct/tech_data/api/ppent/techDataStatus/close`);
  }




}
