import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {AuditModel} from '../shared/model/audit.model';
import {AuditService} from '../shared/services/audit.service';
import {MessageService} from 'primeng/api';

@Component({
  selector: 'app-scheme-audit',
  templateUrl: './scheme-audit.component.html',
  styleUrls: ['./scheme-audit.component.css']
})
export class SchemeAuditComponent implements OnInit, OnChanges {

  display: boolean = false;

  @Input()
  auditInfo: AuditModel;

  @Input()
  scheme: string;


  constructor(
    public auditService: AuditService,
    public messageService: MessageService
  ) { }

  ngOnInit() {
  }

  ngOnChanges() {
    // console.log('sdsd')
  }

  showDialog(scheme: string) {
    // console.log(scheme)
    this.scheme = scheme;
    this.display = true;
  }

  saveScheme(scheme: string) {
    // console.log(scheme);
    this.auditService.saveScheme(this.auditInfo.id, scheme)
      .subscribe(data => {
        // console.log(data.data.hasScheme);
        this.auditInfo.hasScheme = data.data.hasScheme;
        this.addSingle('Схема успешно сохранена');
        this.scheme = '';
        this.display = false;
      }, error => {
        this.addSingle(error.errorMsgRus, 'error');
      })
  }

  addSingle(text: string, status: string = 'success') {
    this.messageService.add({severity: status, summary:'', detail:text});
  }
}
