import {BaseApi} from '../../../shared/core/base-api';
import {Injectable} from '@angular/core';
import {Http} from '@angular/http';



@Injectable()
export class ReferenceBooksService extends  BaseApi {


  constructor(public http: Http) {
    super(http);
  }

  // Получение списка областей

  getObl() {
    return this.get(`api/ppent/schools/obl`);
  }

  // Получение списка районов

  getRaion(oblId: number) {
    return this.get(`api/ppent/schools/obl/${oblId}/raion`);
  }

  // Получение списка КАТО

  getKato(oblId: number, raionId: number, parent: number = 0) {
    return this.get(`api/ppent/schools/obl/${oblId}/raion/${raionId}/kato?parent=${parent}`);
  }

  // Получение списка типов школ

  getSpravSchool() {
    return this.get(`api/ppent/schools/types`);
  }

  // Получение списка форм собственности

  // getSchoolOwnership() {
  //   return this.get(`api/ppent/schools/ownership`);
  // }

  // Получение списка возможных значений ведомственной принадлежности {

  // getSchoolDepartament() {
  //   return this.get(`api/ppent/schools/department`);
  // }

  // Получение списка возможных значений территориальной принадлежности

  // getSchoolLocation() {
  //   return this.get('api/ppent/schools/location');
  // }

  getSchoolCategory(typeId: number) {
    return this.get(`api/ppent/schools/types/${typeId}/category`);
  }


  // Получение списка возможных значений уровней образования

  // getSchoolEduLevel() {
  //   return this.get(`/api/ppent/schools/edu-level`);
  // }

  // Получение списка возможных значений профилей

  // getSprSchoolProfile() {
  //   return this.get(`/api/ppent/schools/profile`);
  // }

  // Получение списка возможных значений скорости интернета

  // getSprNetSpeed() {
  //   return this.get('api/ppent/schools/net-speed');
  // }

  // Получение списка возможных значений характеристик доставки тестируемых на ЕНТ
  getSprStudentDelivery() {
    return this.get(`api/ppent/schools/student-delivery`);
  }

  // Получение списка возможных значений языка обучения
  getSprEduLang() {
    return this.get('api/ppent/schools/edu-lang');
  }

  // Получение списка возможных значений иностранных языков, изучаемых в школе
  // getSprForeingLang() {
  //   return this.get('api/ppent/schools/foreign-lang');
  // }

  // getSchoolPpentListForEdit() {
  //   return this.get('api/ppent/schools/ppent');
  // }

  getClassNoList(schoolId: number) {
    return this.get(`api/ppent/schools/items/${schoolId}/pupils/count-by-class/class-num`);
  }

  getClassLang(schoolId: number) {
    return this.get(`api/ppent/schools/items/${schoolId}/pupils/count-by-class/lang`);
  }

  // Получение списка направлений обучения
  getDirectionList(schoolId: number) {
    return this.get(`api/ppent/schools/items/${schoolId}/pupils/count-by-direction/study-direction`);
  }

  getLangByDirection(schoolId: number) {
    return this.get(`api/ppent/schools/items/${schoolId}/pupils/count-by-direction/lang`);
  }

  getPPENT() {
    return this.get('api/ppent/schools/ppent');
  }
}







