import {Component, OnInit, Input, OnChanges, EventEmitter, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {RegisterModel} from '../../shared/model/register.model';
import {AuditModel} from '../shared/model/audit.model';
import {AuditService} from '../shared/services/audit.service';
import {MessageService} from 'primeng/api';
import {AuditRepository} from '../shared/services/audit.repository';
import {LangRepository} from '../../shared/lang/lang.repository';


@Component({
  selector: 'app-edit-audit',
  templateUrl: './edit-audit.component.html',
  styleUrls: ['./edit-audit.component.css']
})
export class EditAuditComponent implements OnInit, OnChanges {

  myForm: FormGroup;
  display: boolean = false;
  transmitVideoOnlineReason: boolean = false;

  @Output() editAudit: EventEmitter<any> = new EventEmitter();

  auditNum: number;
  audit: AuditModel;

  constructor( public auditService: AuditService,
               private messageService: MessageService,
               public auditRepository: AuditRepository,
               public langRepository: LangRepository
               ) { }

  ngOnInit() {
    this.audit = new AuditModel(
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null
    );


    this.myForm = this.formValidation(this.audit);
  }

  ngOnChanges() {
  }


  show(id: number) {
    this.transmitVideoOnlineReason = false;
    this.auditNum = id;
    this.auditService.getSingleAuditData(id)
      .subscribe(data => {
        // console.log(data.data)
        const find = this.auditRepository.transmitVideoOnlineReasonList.find(item => item.value === data.data.cameraWhyNotCanTransmitVideoByInternetId);
        // console.log(find)
        if (find) {
          if (find.title) {
            this.transmitVideoOnlineReason = true;
          } else {
            this.transmitVideoOnlineReason = false;
          }
        }

        this.auditRepository.start();
        this.auditRepository.getFreeAudit(id)
          .subscribe(() => {
            this.myForm = this.formValidation(data.data);
            this.display = true;
          });

      })
  }

  formValidation (audit: any) {
    return new FormGroup({
      num: new FormControl(audit.num, [Validators.required]),
      nameKaz: new FormControl(audit.nameKaz, [Validators.required]),
      nameRus: new FormControl(audit.nameRus, [Validators.required]),
      addressKaz: new FormControl(audit.addressKaz, [Validators.required]),
      addressRus: new FormControl(audit.addressRus, [Validators.required]),
      placeCount: new FormControl(audit.placeCount, [Validators.required]),
      buildingNameKaz: new FormControl(audit.buildingNameKaz, [Validators.required]),
      buildingNameRus: new FormControl(audit.buildingNameRus, [Validators.required]),
      floor: new FormControl(audit.floor, [Validators.required]),
      isReserve: new FormControl(audit.isReserve, [Validators.required]),
      isCameraExists: new FormControl(audit.isCameraExists, [Validators.required]),
      isSilencerExists: new FormControl(audit.isSilencerExists, [Validators.required]),
      cameraWhyNotHaveId: new FormControl(audit.cameraWhyNotHaveId, [Validators.required]),
      cameraWhyNotHaveText: new FormControl(audit.cameraWhyNotHaveText, [Validators.required]),
      isCanTransmitVideo: new FormControl(audit.isCanTransmitVideo, [Validators.required]),
      isBlindAreasExists: new FormControl(audit.isBlindAreasExists, [Validators.required]),
      isCanTransmitVideoByInternet: new FormControl(audit.isCanTransmitVideoByInternet, [Validators.required]),
      cameraWhyNotCanTransmitVideoByInternetId: new FormControl(audit.cameraWhyNotCanTransmitVideoByInternetId, [Validators.required]),
      cameraWhyNotCanTransmitVideoByInternetText: new FormControl(audit.cameraWhyNotCanTransmitVideoByInternetText, [Validators.required]),
      airConditionSystemTypeId: new FormControl(audit.airConditionSystemTypeId, [Validators.required]),
      toiletLocationTypeId: new FormControl(audit.toiletLocationTypeId, [Validators.required]),
      silencerWhyNotHaveId: new FormControl(audit.silencerWhyNotHaveId, [Validators.required]),
      silencerWhyNotHaveText: new FormControl(audit.silencerWhyNotHaveText, [Validators.required])
    });
  }

  edit() {


    this.auditService.editAudit(this.auditNum, this.myForm.value)
      .subscribe(data => {
        console.log(data);
        this.display = false;
        this.addSingle(this.langRepository.wordList[51]);
        this.editAudit.emit(data.data);
      }, error => {
        this.addSingle(error.errorMsgRus, 'error');
      })
  }


  test(event) {
    if (event.selectedOption.title) {
      this.transmitVideoOnlineReason = true;
    } else {
      this.transmitVideoOnlineReason = false;
    }
  }


  addSingle(text: string, status: string = 'success') {
    this.messageService.add({severity: status, summary:'Service Message', detail: text});
  }



}
