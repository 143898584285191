import {Http} from '@angular/http';
import {Injectable} from '@angular/core';
import {BaseApi} from '../../../shared/core/base-api';
import {AuditModel} from '../model/audit.model';


@Injectable()
export class AuditService extends BaseApi{
  constructor(public http: Http) {
    super(http);
  }


  getAuditList() {
    return this.get('api/ppent/audit/items');
  }

  getScheme(auditNum: number) {
    return this.get(`api/ppent/audit/items/${auditNum}/scheme`);
  }

  getSingleAuditData(auditNum: number) {
    return this.get(`api/ppent/audit/items/${auditNum}`);
  }

  editAudit(auditNum: number, audit: AuditModel) {
    // console.log(audit);
    return this.put(`api/ppent/audit/items/${auditNum}`, audit);
  }

  addAudit(audit: AuditModel) {
    return this.post('api/ppent/audit/items', audit);
  }

  deleteAudit(auditNum: number) {
    return this.delete(`api/ppent/audit/items/${auditNum}`);
  }

  saveScheme(auditNum: number, scheme: any) {
    // console.log(scheme)
    return this.put(`api/ppent/audit/items/${auditNum}/scheme`, scheme);
  }

  getAriCondition() {
    return this.get(`api/ppent/audit/air-condition-systems`);
  }

  // Получение списка видов мест расположения сан.узла для редактирования / добавления данных аудитории
  getToiletLocationList() {
    return this.get(`api/ppent/audit/toilet-locations`);
  }

  getFreeAudit(auditId: number) {
    return this.get(`api/ppent/audit/free-nums?audit-id=${auditId}`);
  }

  getWhyNotHaveReasonList() {
    return this.get(`api/ppent/audit/why-not-have-reasons`);
  }

  getWhyNotCanTransmitVideoOnlineReasonList() {
    return this.get(`api/ppent/audit/why-not-can-video-online`);
  }

  // отчеты
  uploadReportFile(formData, type) {
    return this.post(`api/ppent/reports/${type}/files`, formData);
  }


  getReportFileList(type: string) {
    return this.get(`api/ppent/reports/${type}/files`);
  }

  deleteReport(reportId: number, type: string) {
    return this.delete(`api/ppent/reports/${type}/files/${reportId}`);
  }




}
